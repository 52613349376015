import TKCustomElementFactory from '@tk/utilities/tk.custom.element.factory';

const TARGET_ATTR = 'data-tk-section-target';
const CHECKBOX_ATTR = 'data-tk-checkbox-toggler';

export default class KWSectionToggler extends TKCustomElementFactory {
    checkboxes: NodeListOf<HTMLInputElement>;

    constructor() {
        super();
        this.checkboxes = this.querySelectorAll(`[${CHECKBOX_ATTR}]`);
    }

    connectedCallback(): void {
        if (this.checkboxes.length === 0) return;
        this.registerInputListener();
        this.checkboxes.forEach((checkbox) => {
            this.clearOtherCheckboxes(checkbox);
        });
    }

    registerInputListener() {
        this.checkboxes?.forEach((checkbox) => {
            const onToggle = this.toggleVisibility.bind(this);
            this.pushListener({
                event: 'input',
                element: checkbox,
                action: onToggle,
            });
        });
    }

    toggleVisibility(event: InputEvent) {
        const target = event.currentTarget as HTMLInputElement;
        this.clearOtherCheckboxes(target);
        const id = target.getAttribute(CHECKBOX_ATTR);
        const sections = this.querySelectorAll<HTMLElement>(`[${TARGET_ATTR}*="${id}"]`);
        sections.forEach((section) => {
            const targetName = section.getAttribute(TARGET_ATTR);
            if (targetName?.includes(';')) {
                const targetNames = targetName.split(';');
                const checkboxList = Array.from(this.checkboxes!);
                const atLeastOneChecked = targetNames.some((name) => {
                    const currentCheckbox = checkboxList.find((checkbox) => (
                        checkbox.getAttribute(CHECKBOX_ATTR) === name
                    ));
                    return currentCheckbox?.checked;
                });
                section.hidden = !atLeastOneChecked;
            } else {
                section.hidden = !target.checked;
            }
        });
    }

    clearOtherCheckboxes(target: HTMLInputElement) {
        const checkboxList = Array.from(this.checkboxes!);
        const filteredCheckboxes = checkboxList.filter((checkbox) => checkbox !== target);
        filteredCheckboxes.forEach((checkbox) => {
            const id = checkbox.getAttribute(CHECKBOX_ATTR);
            const sections = this.querySelectorAll<HTMLElement>(`[${TARGET_ATTR}*="${id}"]`);
            sections.forEach((section) => {
                const targetName = section.getAttribute(TARGET_ATTR);
                if (targetName?.includes(';')) {
                    const targetNames = targetName.split(';');
                    const checkboxList = Array.from(this.checkboxes!);
                    const atLeastOneChecked = targetNames.some((name) => {
                        const currentCheckbox = checkboxList.find((checkbox) => (
                            checkbox.getAttribute(CHECKBOX_ATTR) === name
                        ));
                        return currentCheckbox?.checked;
                    });
                    section.hidden = !atLeastOneChecked;
                } else {
                    section.hidden = !checkbox.checked;
                }
            });
        });
    }
}
