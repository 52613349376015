import { b as b$1, a as a$1 } from './chunk-DXM3AJHB.js';
import { a as a$2 } from './chunk-DTSV5FYV.js';

var b=`
    <div>
      <div class="${a$1.MESSAGE}" id="scandit-loadinglabel"></div>
      <div id="scandit-progress" role="progressbar" aria-valuemin="0" aria-valuemax="100" aria-labelledby="scandit-loadinglabel" />
    </div>
`,a="aria-valuenow",v="aria-valuemin",_="aria-valuemax",o=class o{constructor(){this._mounted=!1;this._htmlRoot=null;this._progress=null;this._message=null;}set value(e){var r,l,m,u,d,c;if(!this._mounted)return;if(e==null){(r=this._progress)==null||r.removeAttribute(a),(l=this._progress)==null||l.removeAttribute("style");return}let t=Number((m=this._progress)==null?void 0:m.getAttribute(v)),s=Number((u=this._progress)==null?void 0:u.getAttribute(_));if(e>=t&&e<=s){let p=String(e);(d=this._progress)==null||d.setAttribute(a,p),(c=this._progress)==null||c.setAttribute("style",`--aria-valuenow: ${p}`);}}get value(){var s;let e=(s=this._progress)==null?void 0:s.getAttribute(a);if(e===null)return null;let t=Number(e);return Number.isNaN(t)?null:t}set message(e){this._message&&(this._message.textContent=e);}get message(){var e,t;return (t=(e=this._message)==null?void 0:e.textContent)!=null?t:""}mount(e){var s;if(this._mounted)return;if(!document.head.querySelector(`[data-component=${o.componentName}]`)){let r=document.createElement("style");r.dataset.component=o.componentName,r.append(document.createTextNode(b$1)),document.head.append(r);}let t=document.createElement("div");t.classList.add(a$1.PROGRESSBAR_CONTAINER),t.classList.add(a$1.HIDE),t.innerHTML=b,(s=e.parentNode)==null||s.replaceChild(t,e),this._htmlRoot=t,this._progress=this._htmlRoot.querySelector("[role=progressbar]"),this._message=this._htmlRoot.querySelector(`.${a$1.MESSAGE}`),this.message=a$2.getInstance().get("core.view.loading"),this._mounted=!0;}unmount(){var e,t;this._mounted&&((e=this._htmlRoot)==null||e.remove(),(t=document.head.querySelector(`[data-component=${o.componentName}]`))==null||t.remove(),this._mounted=!1);}show(){this._mounted&&this._htmlRoot&&this._htmlRoot.classList.remove(a$1.HIDE);}hide(){this._mounted&&this._htmlRoot&&this._htmlRoot.classList.add(a$1.HIDE);}};o.componentName="ProgressBarView";var n=o,E=n;

export { E as a };
