import TKCustomElementFactory from '@tk/utilities/tk.custom.element.factory';
import { RectangleItem, isOutsideShapes } from '@tk/utilities/tk.shape.detector';

export default class TKContextMenu extends TKCustomElementFactory {
    buttons: NodeListOf<HTMLButtonElement>;
    items?: HTMLElement;
    copiedItems?: HTMLElement;
    openClassName: string;
    responsiveClassName: string;
    dropDownClassName: string;
    offset: number;
    disableScrolldownBreakpoint?: Breakpoint;
    minWidthBreakpoint: number;
    showAlways: boolean;
    resizeObserver: ResizeObserver;

    constructor() {
        super();

        this.buttons = this.querySelectorAll<HTMLButtonElement>('[data-tk-context-button]');
        this.items = this.querySelector('[data-tk-context-items]') || undefined;
        this.openClassName = this.getAttribute('data-tk-open-class-name') || 'tk-context-menu--open';
        this.responsiveClassName = this.getAttribute('data-tk-responsive-class-name') || 'tk-context-menu--responsive';
        this.dropDownClassName = this.getAttribute('data-tk-dropdown-class-name') || 'tk-context-menu__dropdown';
        this.showAlways = this.hasAttribute('data-tk-show-always');
        this.disableScrolldownBreakpoint = (
            this.getAttribute('data-tk-disable-scrolldown-breakpoint') as Breakpoint
        ) || 's';
        this.minWidthBreakpoint = window.viewport.breakpoints.get(this.disableScrolldownBreakpoint) || 0;
        this.offset = Number(this.getAttribute('data-tk-context-offset')) || 4;
        this.resizeObserver = new ResizeObserver(this.handleResize.bind(this));
    }

    connectedCallback(): void {
        if (this.buttons.length === 0 || !this.items) throw new Error('Context Menu: Elements are missing!');

        this.registerViewportListener();
        (window.viewport.clientWidth < this.minWidthBreakpoint || this.showAlways)
            && this.switchToMobile();
        this.registerClickListener();
    }

    disconnectedCallback(): void {
        super.disconnectedCallback();
        this.resizeObserver.unobserve(this);
    }

    registerClickListener(): void {
        const onClick = this.open.bind(this);
        this.buttons.forEach((button) => {
            this.pushListener({ event: 'click', element: button, action: onClick });
        });
    }

    registerCloseListener() {
        const handleClose = this.close.bind(this);
        this.pushListener({ event: 'click', element: window, action: handleClose });
    }

    registerViewportListener(): void {
        const onViewportResize = this.handleViewportResize.bind(this);
        this.pushListener({
            event: 'tk-viewport-resize',
            element: window.viewport,
            action: onViewportResize,
        });
    }

    handleResize(entries: ResizeObserverEntry[]) {
        entries.forEach(() => {
            if (!this.copiedItems) return;
            this.setPosition(this.copiedItems);
        });
    }

    handleViewportResize(event: CustomEvent): void {
        (event.detail.width < this.minWidthBreakpoint || this.showAlways)
            ? this.switchToMobile()
            : this.switchToDesktop();
    }

    getVisibleButton() {
        const buttonList = Array.from(this.buttons);
        return buttonList.find((button) => button.checkVisibility());
    }

    switchToDesktop() {
        const button = this.getVisibleButton();
        if (!button) return;
        button.hidden = true;
        this.classList.remove(this.responsiveClassName);
    }

    switchToMobile() {
        const button = this.getVisibleButton();
        if (!button) return;
        button.hidden = false;
        this.classList.add(this.responsiveClassName);
    }

    copyItemsIfNotExists() {
        const copiedItems = this.items!.cloneNode(true) as HTMLElement;
        copiedItems.classList.add(this.dropDownClassName);
        this.copiedItems = copiedItems;
        document.body.insertAdjacentElement('beforeend', copiedItems);
    }

    open() {
        !this.copiedItems && this.copyItemsIfNotExists();
        if (!this.copiedItems) return;
        this.setPosition(this.copiedItems);
        this.resizeObserver.observe(document.body);
        setTimeout(() => {
            this.registerCloseListener();
        }, 0);
    }

    setPosition(element: HTMLElement) {
        const button = this.getVisibleButton();
        if (!button) return;
        element.classList.add(this.openClassName);
        const {
            top, height, left, right,
        } = button.getBoundingClientRect();
        const leftPosition = left + window.scrollX;
        const rightPosition = right + window.scrollX;

        element.style.top = `${top + height + window.scrollY + this.offset}px`;

        if (window.innerWidth <= element.clientWidth + leftPosition) {
            element.style.left = `${rightPosition - element.clientWidth}px`;
        } else {
            element.style.left = `${leftPosition}px`;
        }
    }

    close(event: MouseEvent) {
        const button = this.getVisibleButton();
        if (!this.copiedItems || !button) return;
        const buttonRect = button.getBoundingClientRect();
        const dropdownRect = this.copiedItems.getBoundingClientRect();
        const rectangles = [
            { rectangle: buttonRect },
            { rectangle: dropdownRect },
        ] as RectangleItem[];
        if (isOutsideShapes(event, rectangles)) {
            this.copiedItems.classList.remove(this.openClassName);
            this.removeListener(window);
            this.resizeObserver.unobserve(document.body);
        }
    }
}
