import { sendNotificationByItems } from './tk.notification';

export interface FetchAsyncParams<T> {
    requestURL: string;
    resolveHandler: (response: TKResponse<T>) => void;
    rejectHandler?: (response: unknown) => void;
    payload?: Record<string, string | undefined | null> | FormData | URLSearchParams;
    decodeJSONResponse?: boolean;
    cache?: RequestCache;
    showMessages?: boolean;
}

const getDefaultErrorFnc = (error: unknown) => {
    throw new Error(`Request failed: ${error}`);
};

export const setAsyncUrl = (checkForReadOnlyContext = false) => {
    const asyncUrl = '/WebPortal/async.aspx';

    if (
        !window.opacc
        || !window.opacc.systemUrls
        || !checkForReadOnlyContext
        || !window.opacc.systemUrls.asyncReadOnly
    ) return asyncUrl;

    return window.opacc.systemUrls.asyncReadOnly;
};

const prepareJsonResponse = <T>(response: WASResponse, showMessages: boolean) => {
    const data = window.atob(response.data);
    const html = document.createElement('div');
    html.innerHTML = data;

    const json = response.contentType === 'application/json' && html.innerText !== ''
        ? JSON.parse(html.innerText)
        : {};

    showMessages && sendNotificationByItems(response.messages, json);

    return {
        status: response.status,
        messages: response.messages,
        dataAsBase64: response.data,
        contentType: response,
        success: response.status === 'ok',
        dataAsHtml: response.contentType === 'text/html' ? html.innerText : '',
        dataAsJson: json,
        data,
    } as unknown as TKResponse<T>;
};

const recordToURLSearchParams = (data: Record<string, string | undefined | null>): URLSearchParams => {
    const searchParams = new URLSearchParams();
    Object.entries(data).forEach(([key, value]) => {
        searchParams.append(key, value || '');
    });
    return searchParams;
};

const formDataToURLSearchParams = (formData: FormData): URLSearchParams => {
    const searchParams = new URLSearchParams();
    formData.forEach((value, key) => {
        searchParams.append(key, value as string);
    });
    return searchParams;
};

export const fetchRequest = async <T>({
    requestURL,
    resolveHandler,
    rejectHandler = getDefaultErrorFnc,
    payload,
    decodeJSONResponse = true,
    cache = 'default',
    showMessages = true,
}: FetchAsyncParams<T>) => {
    const headers = new Headers({
        'x-requested-with': 'XMLHttpRequest',
        'response-type': 'json',
    });

    let body;
    if (payload) {
        headers.set('content-type', 'application/x-www-form-urlencoded; charset=UTF-8');
        if (payload instanceof URLSearchParams) {
            body = payload;
        } else if (payload instanceof FormData) {
            body = formDataToURLSearchParams(payload);
        } else if (payload instanceof Object) {
            body = recordToURLSearchParams(payload);
        }
    }

    const init: RequestInit = {
        method: payload ? 'POST' : 'GET',
        headers,
        cache,
        credentials: 'include',
        body,
    };

    try {
        const response = await fetch(requestURL, init);
        const contentType = response.headers.get('content-type');
        const data = contentType?.includes('application/json')
            ? await response.json()
            : await response.text();
        const preparedData = decodeJSONResponse ? prepareJsonResponse<T>(data, showMessages) : data;
        resolveHandler(preparedData);
    } catch (error) {
        rejectHandler(error);
    }
};
