import {
    Camera,
    DataCaptureContext,
    DataCaptureView,
    FrameSourceState,
    configure,
    CameraSwitchControl,
} from 'scandit-web-datacapture-core';
import {
    BarcodeCapture,
    barcodeCaptureLoader,
    BarcodeCaptureSettings,
    Symbology,
} from 'scandit-web-datacapture-barcode';

import TKCustomElementFactory from '@tk/utilities/tk.custom.element.factory';

export default class KWScan extends TKCustomElementFactory {
    view: DataCaptureView;
    context?: DataCaptureContext;
    settings?: BarcodeCaptureSettings;
    searchForm?: HTMLFormElement;

    constructor() {
        super();
        this.view = new DataCaptureView();

        this.searchForm = document.querySelector('[data-tk-search]') || undefined;
    }

    connectedCallback(): void {
        this.loader();
        this.initScan();
    }

    loader() {
        this.view.connectToElement(this);
        this.view.showProgressBar();
        this.view.setProgressBarMessage('Loading ...');
    }

    hideLoader() {
        this.view.hideProgressBar();
    }

    setScanningBehaviour() {
        this.settings = new BarcodeCaptureSettings();
        this.settings.enableSymbologies([
            Symbology.Code128,
            Symbology.Code39,
            Symbology.QR,
            Symbology.EAN8,
            Symbology.UPCE,
            Symbology.EAN13UPCA,
        ]);
    }

    async initScan() {
        // Set the progress bar to be in an indeterminate state
        this.view.setProgressBarPercentage(null);
        this.view.setProgressBarMessage('Accessing Camera...');

        await configure({
            licenseKey: process.env.SCANDIT_API || '',
            libraryLocation: new URL(
                'webportal/config/layout/themes/tk/vendor/scandit/library/engine',
                document.baseURI.split('/').slice(0, 4).join('/'), // for pages like search
            ).toString(),
            moduleLoaders: [barcodeCaptureLoader()],
        });

        this.context = await DataCaptureContext.create();
        await this.view.setContext(this.context);

        // set scanning behaviour
        this.setScanningBehaviour();

        if (!this.context && !this.settings) return;
        const barcodeCapture = await BarcodeCapture.forContext(this.context, this.settings!);
        this.listener(barcodeCapture);

        const camera: Camera = Camera.default;
        const cameraSettings = BarcodeCapture.recommendedCameraSettings;

        if (camera) {
            await camera.applySettings(cameraSettings);
            await this.context.setFrameSource(camera);
            await camera.switchToDesiredState(FrameSourceState.On);
        }

        this.view.addControl(new CameraSwitchControl());

        this.hideLoader();
    }

    listener(barcodeCapture: BarcodeCapture) {
        const listener = {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            didScan: async (barcodeCapture: BarcodeCapture, session: any) => {
                const barcode = session.newlyRecognizedBarcodes[0];
                await barcodeCapture.setEnabled(false);
                this.searchArticle(barcode.data);
            },
        };

        barcodeCapture.addListener(listener);
    }

    searchArticle(barCode:string):void {
        if (!this.searchForm) return;
        const input = this.searchForm.querySelector('input');

        if (!input) return;

        input.value = barCode;
        this.searchForm.submit();
    }
}
