var r={PROGRESSBAR_CONTAINER:"scandit-progressbar-container",MESSAGE:"scandit-progressbar-message",HIDE:"scandit-progressbar-hide"},e=`
    @media (prefers-color-scheme: dark) {
      .${r.PROGRESSBAR_CONTAINER} {
        --foreground-color: white;
        --background-color: black;
      }
    }

    @media (prefers-color-scheme: light) {
      .${r.PROGRESSBAR_CONTAINER} {
        --foreground-color: black;
        --background-color: white;
      }
    }
    
    .${r.PROGRESSBAR_CONTAINER} {
      --accent-color: #62d1da;
      --secondary-color: #dae1e7;
      --100-w: 15rem;
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      z-index: 1;
      background-color: var(--background-color);
    }
    
    .${r.PROGRESSBAR_CONTAINER}.${r.HIDE} {
       display: none;
    }
    
    .${r.PROGRESSBAR_CONTAINER} .${r.MESSAGE} {
      text-align: center;
      font-family: "Open Sans", sans-serif;
      margin-bottom: 1rem;
      color: var(--foreground-color);
    }
    
    .${r.PROGRESSBAR_CONTAINER} [role="progressbar"] {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      position: relative;
      overflow: hidden;
      border: none;
      width: var(--100-w);
      height: 0.5rem;
    }
    
    .${r.PROGRESSBAR_CONTAINER} [role="progressbar"]::before,
    .${r.PROGRESSBAR_CONTAINER} [role="progressbar"]::after {
      content: " ";
      position: absolute;
      top: 0;
      left: 0;
      background: var(--accent-color);
      height: 0.5rem;
      border-radius: 0.5rem;
    }
    
    .${r.PROGRESSBAR_CONTAINER} [role="progressbar"]:not([aria-valuenow])::after {
      width: calc(var(--100-w) / 3.5);
      animation: indeterminate-progress-bar 2.5s ease infinite;
      z-index: 1;
    }

    .${r.PROGRESSBAR_CONTAINER} [role="progressbar"][aria-valuenow]::after {
      width: 0;
      animation: none;
    }
    
    .${r.PROGRESSBAR_CONTAINER} [role="progressbar"][aria-valuenow]::before {
      width: calc(var(--aria-valuenow) * 1%);
      background-color: var(--accent-color);
      border-radius: 0.5rem;
      transition: width 0.3s linear;
    }
    
    .${r.PROGRESSBAR_CONTAINER} [role="progressbar"] {
      background-color: var(--secondary-color);
      border-radius: 0.5rem;
    }
    
    @-webkit-keyframes indeterminate-progress-bar {
      0% {
        left: 0%;
      }
      50% {
        left: calc(var(--100-w) - var(--100-w) / 3.5);
      }
    }
    
    @keyframes indeterminate-progress-bar {
      0% {
        left: 0%;
      }
      50% {
        left: calc(var(--100-w) - var(--100-w) / 3.5);
      }
    }
`,o=e;

export { r as a, o as b };
