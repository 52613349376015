import { a } from './chunk-RXA3ZFIT.js';

function A(){return `
    .${a.CONTAINER_CLASS_NAME}::before {
      content: " ";
      position: absolute;
      top: 0;
      left: 0;
      width: 1px;
      height: 1px;
      background-color: white;
      pointer-events: none;
      opacity: 0;
      animation: scandit-rotation 1s linear infinite;
    }

    @keyframes scandit-rotation {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }

    .${a.CONTAINER_CLASS_NAME} {
      display: flex;
      /* Without this the flex child overflows its parent on Chrome mobile: */
      flex-direction: column;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
      overflow: hidden;
    }

    .${a.PAINTBOARDS_CONTAINER_CLASS_NAME} {
      position: relative;
      width: 100%;
      height: inherit;
    }

    .${a.PAINTBOARD_CLASS_NAME} {
      position: relative;
      width: 100%;
      height: 100%;
      min-width: 1px;
      min-height: 1px;
      background-color: black;
      aspect-ratio: 16 / 9;
    }

    .${a.PAINTBOARD_CLASS_NAME}.${a.PAINTBOARD_CLASS_NAME_STATE_ON} {
      aspect-ratio: auto
    }

    .${a.PAINTBOARD_CLASS_NAME} video {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: opacity 300ms;
    }

    .${a.PAINTBOARD_CLASS_NAME} .${a.MIRRORED_CLASS_NAME} {
      transform: scale(-1, 1);
    }

    .${a.PAINTBOARD_CLASS_NAME} .${a.CAMERA_RECOVERY_CLASS_NAME} {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 5;
      cursor: pointer;
      background-color: #000;
      color: #fff;
    }

    .${a.PAINTBOARD_CLASS_NAME} .${a.ERROR_CLASS_NAME} {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      z-index: 6;
      color: white;
      background-color: rgba(0,0,0,0.5);
    }

    .${a.PAINTBOARD_CLASS_NAME} .${a.HINT_CONTAINER_CLASS_NAME} {
      position: absolute;
      width: 100%;
      top: 5vh;
      bottom: 5vh;
      display: grid;
      pointer-events: none;
    }

    .${a.PAINTBOARD_CLASS_NAME} .${a.HINT_CLASS_NAME} {
      width: fit-content;
      color: black;
      background-color: white;
      padding: 10px;
    }

    .${a.PAINTBOARD_CLASS_NAME} .${a.CAMERA_RECOVERY_CLASS_NAME}[hidden],
    .${a.PAINTBOARD_CLASS_NAME} .${a.ERROR_CLASS_NAME}[hidden] {
      display: none;
    }
    .${a.PAINTBOARD_CLASS_NAME} .${a.HINT_CLASS_NAME}[hidden] {
      display: none;
    }

    .${a.PAINTBOARD_CLASS_NAME} canvas {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
      transition: opacity 300ms;
      pointer-events: none;
    }

    .${a.PAINTBOARD_CLASS_NAME} .${a.CONTROLS_CLASS_NAME} {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
    }

    .${a.PAINTBOARD_CLASS_NAME} .${a.CONTROL_WIDGET_CLASS_NAME}-${a.TORCH_SWITCH_CONTROL_CLASS_NAME} {
      position: absolute;
      top: 24px;
      left: 16px;
    }

    .${a.PAINTBOARD_CLASS_NAME} .${a.CONTROL_WIDGET_CLASS_NAME}-${a.CAMERA_SWITCH_CONTROL_CLASS_NAME} {
      position: absolute;
      top: 24px;
      right: 16px;
    }

    .${a.PAINTBOARD_CLASS_NAME} .${a.CONTROL_WIDGET_CLASS_NAME}-${a.CAMERA_FOV_SWITCH_CONTROL_CLASS_NAME} {
      position: absolute;
      bottom: 24px;
      right: 24px;
    }

    .${a.PAINTBOARD_CLASS_NAME} .${a.SINGLE_IMAGE_UPLOADER_CONTAINER_CLASS_NAME} {
      -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }

    .${a.PAINTBOARD_CLASS_NAME} .${a.SINGLE_IMAGE_UPLOADER_CONTAINER_CLASS_NAME} label {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .${a.PAINTBOARD_CLASS_NAME} .${a.SINGLE_IMAGE_UPLOADER_CONTAINER_CLASS_NAME} label input[type="file"] {
      position: absolute;
      top: -9999px;
    }

    .${a.PAINTBOARD_CLASS_NAME} .${a.SINGLE_IMAGE_UPLOADER_CONTAINER_CLASS_NAME} label button {
      pointer-events: none;
    }

    .${a.CONTROL_WIDGET_CLASS_NAME} {
      width: 32px;
      height: 32px;
      background-color: transparent;
      background-repeat: no-repeat;
      border: none;
      cursor: pointer;
    }

    .${a.CONTROL_WIDGET_CLASS_NAME} + .${a.CONTROL_WIDGET_CLASS_NAME} {
      margin-left: 16px;
    }

    .${a.FROZEN_FRAME} {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      opacity: 1;
    }
    
    .${a.FROZEN_FRAME}[hidden] {
      opacity: 0;
      pointer-events: none;
      visibility: hidden;
    }
  `}

export { A as a };
