import TKCustomElementFactory from '@tk/utilities/tk.custom.element.factory';

type FieldElement = HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement | undefined;
type AddonType = 'prefix' | 'suffix';

enum HoverState {
    ACTIVE = 'active',
    INACTIVE = 'inactive',
}

export default class TKFormElement extends TKCustomElementFactory {
    fieldElement: FieldElement;
    labelElement: HTMLLabelElement | undefined;
    isMaterial: boolean;
    materialClass: string;
    materialNumberInputClass: string;
    materialLabelInsideClass: string;
    materialSelectFocussedClass: string;
    materialLoadedClass: string;
    addonElement: HTMLSpanElement | undefined;
    addonSuffixDistance: number;
    addonPrefixDistance: number;
    addonDefaultPadding: number;
    isPasswordInput: boolean;
    waitForSurroundingContent: HTMLElement | undefined;
    disableOnAddonClick: boolean;
    onTransitionEnd: () => void;

    constructor() {
        super();
        this.fieldElement = this.querySelector('input, select, textarea') as FieldElement;
        this.labelElement = this.querySelector('label')
            || undefined;
        this.isMaterial = this.hasAttribute('data-tk-is-material');
        this.materialClass = this.getAttribute('data-tk-material-class')
            || 'tk-form-material';
        this.materialNumberInputClass = this.getAttribute('data-tk-material-number-input-class')
            || 'tk-form-material--number';
        this.materialLabelInsideClass = this.getAttribute('data-tk-material-label-inside-class')
            || 'tk-form-material--label-inside';
        this.materialSelectFocussedClass = this.getAttribute('data-tk-material-select-focussed-class')
            || 'tk-form-select--focussed';
        this.materialLoadedClass = this.getAttribute('data-tk-material-loaded-class')
            || 'tk-form-material--loaded';
        this.isPasswordInput = this.hasAttribute('data-tk-toggle-password');
        this.disableOnAddonClick = this.hasAttribute('data-tk-disable-on-addon-click');
        this.addonElement = this.querySelector<HTMLSpanElement>('[data-tk-addon]')
            || undefined;
        this.waitForSurroundingContent = (this.closest('[data-wait-for-surrounding-content]') as HTMLElement)
            || undefined;
        this.addonSuffixDistance = 0;
        this.addonPrefixDistance = 0;
        this.addonDefaultPadding = parseInt(this.getAttribute('data-tk-addon-default-padding') || '0', 10)
            || 4;
        this.onTransitionEnd = () => {};
    }

    connectedCallback() {
        if (this.isNumberInput() || this.waitForSurroundingContent) {
            this.onDomContentLoaded();
            return;
        }
        this.checkRequiredAttribute();
        this.registerAddonClickedListener();
        this.registerAddonHoverListener();
        this.registerShowPasswordClickedListener();
        this.initMaterialInput();
        this.observeDisabledAttribute();
    }

    /**
     * For the Material Number Input or forms inside of tables, it is necessary that all surrounding elements have been
     * fullyloaded before the Material Number Element itself is displayed.
     * This is because the position of the label in the collapsed state
     * needs to be calculated for the Material Number Input. This calculation depends on the surrounding elements
     */
    onDomContentLoaded() {
        document.addEventListener('DOMContentLoaded', () => {
            this.checkRequiredAttribute();
            this.registerAddonClickedListener();
            this.registerAddonHoverListener();
            this.registerShowPasswordClickedListener();
            this.initMaterialInput();
            this.observeDisabledAttribute();
        });
    }

    // #region Required
    checkRequiredAttribute() {
        if (!this.labelElement || !this.fieldElement || !this.fieldElement.hasAttribute('required')) return;
        this.labelElement.textContent = `${this.labelElement.textContent} *`;
    }
    // #endregion

    // #region disabled
    observeDisabledAttribute() {
        if (!this.fieldElement || !this.labelElement) return;

        // inital check
        this.checkDisabledAttribute();

        const observerConfig = { attributes: true, attributeFilter: ['disabled'] };

        const onCheckDiabledAttribute = this.checkDisabledAttribute.bind(this);

        const observer = new MutationObserver(onCheckDiabledAttribute);
        observer.observe(this.fieldElement, observerConfig);
    }

    checkDisabledAttribute() {
        const addDisabledClass = this.fieldElement!.hasAttribute('disabled');
        this.classList.toggle('tk-form-input--disabled', addDisabledClass);
    }
    // #endregion

    // #region Addon
    registerAddonClickedListener() {
        if (!this.addonElement || this.disableOnAddonClick) return;

        const addonClickHandler = this.onAddonClicked.bind(this);

        this.pushListener({ event: 'click', element: this.addonElement, action: addonClickHandler });
    }

    registerAddonHoverListener() {
        if (!this.addonElement) return;
        const onHoverStartHandler = this.onAddonHover.bind(this, HoverState.ACTIVE);
        this.pushListener({ event: 'mouseenter', element: this.addonElement, action: onHoverStartHandler });
        const onHoverEndHandler = this.onAddonHover.bind(this, HoverState.INACTIVE);
        this.pushListener({ event: 'mouseleave', element: this.addonElement, action: onHoverEndHandler });
    }

    onAddonHover(hoverState: HoverState) {
        if (
            !this.fieldElement
            || (!(this.fieldElement instanceof HTMLInputElement)
            && !(this.fieldElement instanceof HTMLTextAreaElement))
        ) throw new Error('No Input element found');

        this.classList.toggle(
            'tk-form--addon-hover',
            (hoverState === HoverState.ACTIVE) && !this.fieldElement.disabled,
        );
    }

    onAddonClicked() {
        if (
            !this.fieldElement
            || (!(this.fieldElement instanceof HTMLInputElement)
            && !(this.fieldElement instanceof HTMLTextAreaElement))
        ) throw new Error('No Input element found');

        if (this.isPasswordInput) return;
        this.fieldElement.focus();
        const lastCharPosition = this.fieldElement.value.length;
        const isNumberInput = this.fieldElement.type === 'number';
        if (isNumberInput) {
            this.fieldElement.setAttribute('type', 'text');
        }
        this.fieldElement.setSelectionRange(lastCharPosition, lastCharPosition);
        if (isNumberInput) {
            this.fieldElement.setAttribute('type', 'number');
        }
    }

    registerShowPasswordClickedListener() {
        if (!this.isPasswordInput || !this.addonElement) return;

        const passwordShowClickHandler = this.onPasswordShowClicked.bind(this);
        this.pushListener({ event: 'mousedown', element: this.addonElement, action: passwordShowClickHandler });
        this.pushListener({ event: 'mouseup', element: this.addonElement, action: passwordShowClickHandler });
        this.pushListener({ event: 'touchstart', element: this.addonElement, action: passwordShowClickHandler });
        this.pushListener({ event: 'touchend', element: this.addonElement, action: passwordShowClickHandler });
    }

    onPasswordShowClicked(event: Event) {
        if (
            !this.fieldElement
            || !(this.fieldElement instanceof HTMLInputElement)
        ) throw new Error('Missing input element');

        if (event.type === 'mousedown' || event.type === 'touchstart') {
            this.fieldElement.type = 'text';
        } else {
            this.fieldElement.type = 'password';
        }
    }
    // #endregion

    // #region Material
    initMaterialInput() {
        if (!this.isMaterial) return;
        if (!this.fieldElement) throw new Error('No element found');

        this.classList.add(this.materialClass);

        if (this.isNumberInput()) {
            this.classList.add(this.materialNumberInputClass);
        }

        this.registerLabelTransitionEndListener();
        this.calculateAddonWidth();
        this.setMaterialLabelState();
        this.registerMaterialValueChangeListener();
        this.registerMaterialSelectFocusChangeListener();
    }

    /**
     * This function is mainly used to check whether a valid value has been
     * set for the data-tk-addon attribute
     * @returns addonType: string representing the addon type
     */
    getAddonType() {
        if (!this.addonElement) throw new Error('Missing addon');
        const addonType = this.addonElement.getAttribute('data-tk-addon') as AddonType;

        if ((addonType !== 'prefix') && (addonType !== 'suffix')) throw new Error('Wrong addon type');

        return addonType;
    }

    /**
     * Checks whether the element contains a value or not. This information is required
     * to determine whether the label should be displayed inside or outside the element.
     * @returns isEmptyElement: boolean
     */
    checkForValue() {
        let isEmptyElement;

        if (this.fieldElement instanceof HTMLSelectElement) {
            const selectedOption = this.querySelector('option:checked');
            const isPlaceholderOption = selectedOption?.hasAttribute('data-tk-empty-option');
            isEmptyElement = isPlaceholderOption;
        } else {
            isEmptyElement = this.fieldElement?.value === '';
        }

        return isEmptyElement;
    }

    /**
     * This function implements the material functionality by displaying the label either
     * inside or outside the control, depending on the content
     */
    setMaterialLabelState() {
        // check input value
        const isEmptyMaterialInput = this.checkForValue();

        if (isEmptyMaterialInput) {
            this.classList.add(this.materialLabelInsideClass);
            if (!this.labelElement) throw new Error('Missing label element');

            if (this.isNumberInput()) {
                this.setupMaterialNumberLabelStyle();
            }

            if (!this.isNumberInput()) {
                this.setupMaterialDefaultLabelStyle();
            }
        } else {
            this.classList.remove(this.materialLabelInsideClass);
            if (!this.labelElement) throw new Error('Missing label element');
            this.labelElement.removeAttribute('style');
        }
    }

    /**
     * This function calculates the label position for a material input that is not of type
     * number and where the label is located within the input
     */
    setupMaterialDefaultLabelStyle() {
        if ((this.addonPrefixDistance === 0 && this.addonSuffixDistance === 0)) return;
        this.labelElement!.style.marginLeft = this.addonPrefixDistance > 0
            ? `${this.addonPrefixDistance + this.addonDefaultPadding}px` : '0px';
        this.labelElement!.style.marginRight = this.addonSuffixDistance > 0
            ? `${this.addonSuffixDistance + this.addonDefaultPadding}px` : '0px';
        this.labelElement!.style.paddingInline = '0';
    }

    /**
     * This function calculates the label position for a material input of type Number where
     *  the label is located within the input
     */
    setupMaterialNumberLabelStyle() {
        this.labelElement!.style.paddingInline = '0';
        const labelWidthWithPadding = this.labelElement!.getBoundingClientRect().width;
        const computedLabelStyle = window.getComputedStyle(this.labelElement!);
        const labelPaddingLeft = parseFloat(computedLabelStyle.paddingLeft);
        const labelPaddingRight = parseFloat(computedLabelStyle.paddingRight);
        const labelWidth = (labelWidthWithPadding - labelPaddingLeft - labelPaddingRight);

        const formControlWidth = this.getBoundingClientRect().width;

        const computetInputStyle = window.getComputedStyle(this.fieldElement!);
        const inputPaddingRight = parseFloat(computetInputStyle.paddingRight);

        const addonWidth = (this.addonElement && (this.getAddonType() === 'suffix'))
            ? this.addonElement.getBoundingClientRect().width : 0;

        const xOffset = Math.round(formControlWidth - labelWidth - addonWidth - inputPaddingRight);

        this.labelElement!.style.paddingLeft = `${xOffset}px`;
    }

    /**
     * This function checks whether the current control has an addon. If so, depending
     * on the type of control, it checks whether the material label requires additional
     * spacing in the folded state
     */
    calculateAddonWidth() {
        if (!this.addonElement) return;
        if (this.fieldElement instanceof HTMLSelectElement) return;

        /* The text is displayed right-aligned for the number inputs.
        They must therefore be treated separately */
        const isNumberInput = this.isNumberInput();
        const addonType = this.getAddonType();

        if (!isNumberInput && (addonType === 'prefix')) {
            this.addonPrefixDistance = this.addonElement.offsetWidth;
        } else if (isNumberInput && (addonType === 'suffix')) {
            this.addonSuffixDistance = this.addonElement.offsetWidth;
        }
    }

    /**
     * Checks whether the input is of type Number. This information is required because
     * the text does not start on the left when the number input is collapsed
     * @returns boolean: representing is input of type number
     */
    isNumberInput() {
        if (!(this.fieldElement instanceof HTMLInputElement)) return false;

        return (this.fieldElement && (this.fieldElement.type === 'number'));
    }

    /**
     * Listener for material control value changes
     */
    registerMaterialValueChangeListener() {
        const onValueChanged = this.setMaterialLabelState.bind(this);
        this.pushListener({ event: 'input', element: this.fieldElement!, action: onValueChanged });
    }

    /**
     * Is required so that a class can be added to the focused material select
     * element that colors the text of the select white
     */
    registerMaterialSelectFocusChangeListener() {
        if (!(this.fieldElement instanceof HTMLSelectElement)) return;

        const onFocusChanged = this.onMaterialSelectFocusChanged.bind(this);

        this.pushListener({ event: 'focusin', element: this.fieldElement, action: onFocusChanged });
        this.pushListener({ event: 'focusout', element: this.fieldElement, action: onFocusChanged });
    }

    /**
     * This function is used to add a class to the focused material select, which sets
     * the font to white if no option has been selected
     * @param event: Used to determin if focus has been set or removed
     */
    onMaterialSelectFocusChanged(event: Event) {
        const { type } = event;

        if (type === 'focusin') {
            this.classList.add(this.materialSelectFocussedClass);
        } else {
            this.classList.remove(this.materialSelectFocussedClass);
        }
    }

    /**
     * This function fades in the material element as soon as the label is in
     * the correct position
     */
    registerLabelTransitionEndListener() {
        if (!this.labelElement) throw new Error('Missing label element');

        this.onTransitionEnd = this.addMaterialLoadedModifier.bind(this);
        this.pushListener({ event: 'transitionend', element: this.labelElement, action: this.onTransitionEnd });
    }

    addMaterialLoadedModifier() {
        this.classList.add(this.materialLoadedClass);
        this.labelElement!.removeEventListener('transitionend', this.onTransitionEnd);
    }
    // #endregion
}
