import TKCustomElementFactory from '@tk/utilities/tk.custom.element.factory';
import TKBasketAction, { BasketAction, BasketData } from '@tk/utilities/tk.basket.action';
import { NotificationType, sendNotification } from '@tk/utilities/tk.notification';
import { formErrorMessages } from '@tk/utilities/tk.messages';

export enum BasketActionTypes {
    ADD = 'add',
    CHANGE = 'change',
    DELETE = 'delete',
    ADD_ALL = 'addall',
    DELETE_ALL = 'deleteall',
}

export default class TKBasketButton extends TKCustomElementFactory {
    basketAction: TKBasketAction;
    action: BasketActionTypes;
    button?: HTMLButtonElement;
    variantWrapper?: HTMLElement;
    counterWrapper?: HTMLElement;
    decreaser?: HTMLButtonElement;
    increaser?: HTMLButtonElement;
    counter?: HTMLInputElement;
    counters?: NodeListOf<HTMLInputElement>;
    timer?: ReturnType<typeof setTimeout>;

    constructor() {
        super();

        this.basketAction = new TKBasketAction(this);
        this.action = this.getAttribute('data-tk-action') as BasketActionTypes || BasketActionTypes.ADD;
        this.button = this.querySelector<HTMLButtonElement>('[data-tk-basket-button]') || undefined;
        this.counterWrapper = this.querySelector('[data-tk-counter]') || undefined;
        this.variantWrapper = this.querySelector('[data-tk-basket-variants]') || undefined;
        if (this.counterWrapper) {
            this.decreaser = this.querySelector<HTMLButtonElement>('[data-tk-counter-decreaser]') || undefined;
            this.increaser = this.querySelector<HTMLButtonElement>('[data-tk-counter-increaser]') || undefined;
            this.counter = this.querySelector<HTMLInputElement>('[data-tk-input-quantity]') || undefined;
            this.counters = this.querySelectorAll<HTMLInputElement>('[data-tk-input-quantity]');
        }
    }

    connectedCallback(): void {
        if (!this.button) throw new Error('Basket: Button is missing!');
        this.registerClickListener();
        this.counterWrapper && this.registerCounter();
        this.registerBasketListener();
        this.variantWrapper && this.registerVariantListener();
    }

    registerClickListener() {
        const onClick = this.handleBasketClick.bind(this);
        this.pushListener({ event: 'click', element: this.button!, action: onClick });
    }

    registerVariantListener() {
        const onInput = this.updateDimensions.bind(this);
        const fieldElement = this.variantWrapper?.querySelector('input[type="hidden"]');
        fieldElement && this.pushListener({ event: 'change', element: fieldElement, action: onInput });
    }

    registerCounter() {
        if (!this.decreaser || !this.increaser) return;
        if (this.action === BasketActionTypes.CHANGE) {
            const onChange = this.change.bind(this);
            this.pushListener({ event: 'click', element: this.decreaser, action: onChange });
            this.pushListener({ event: 'click', element: this.increaser, action: onChange });
            this.counters?.forEach((counter) => {
                this.pushListener({ event: 'change', element: counter, action: onChange });
            });
        } else {
            const onDecrease = this.decrease.bind(this);
            this.pushListener({ event: 'click', element: this.decreaser, action: onDecrease });
            const onIncrease = this.increase.bind(this);
            this.pushListener({ event: 'click', element: this.increaser, action: onIncrease });
        }
    }

    registerBasketListener() {
        this.basketAction.subscribe((event) => {
            const basketActionHandler: Record<BasketAction, () => void> = {
                fetching: () => {},
                'refreshed-list': () => event.data && this.closeDialog(event.data),
                'refreshed-basket': () => {},
            };
            basketActionHandler[event.action]();
        });
    }

    closeDialog(data: BasketData) {
        this.button!.disabled = false;
        if (!data.success) return;
        const dialog = this.closest('dialog');
        if (!dialog) return;
        dialog.close();
    }

    handleBasketClick() {
        this.button!.disabled = true;
        this.validateQuantity();
        this.basketAction.fetch(false);
    }

    increase() {
        if (!this.counter || !this.increaser) return;
        const {
            step, max, value,
        } = this.counter;
        const valueNumber = Number(value);
        if (Number(max) !== 0 && valueNumber >= Number(max)) return;
        this.counter.value = String(valueNumber + (Number(step) || 1));
    }

    decrease() {
        if (!this.counter || !this.decreaser) return;
        const {
            step, min, value,
        } = this.counter;
        const valueNumber = Number(value);
        if (valueNumber <= (Number(min) || 1)) return;
        this.counter.value = String(valueNumber - (Number(step) || 1));
    }

    change(event: KeyboardEvent) {
        TKBasketAction.quantityChanger(event);
        this.timer && clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.button?.click();
        }, 500);
    }

    validateQuantity() {
        const shouldCheckDimension = this.counter?.getAttribute('data-tk-art-dim-check-cd') === '1';
        if (!shouldCheckDimension || !this.counter) return;
        const step = Number(this.counter.step) || 1;
        const currentValue = Number(this.counter.value) || 1;
        const max = Number(this.counter.max);
        const min = Number(this.counter.min);

        if (currentValue % step === 0) return;
        let result = Math.ceil(currentValue / step) * step;
        if (min > 0 && result < min) result += step;
        if (max > 0 && result > max) result -= step;
        this.counter.value = String(result);
        sendNotification(
            NotificationType.WARNING,
            formErrorMessages.stepAdjust
                .replace('{currentValue}', String(currentValue))
                .replace('{newValue}', String(result))
                .replace('{step}', String(step)),
        );
    }

    updateDimensions(event: Event): void {
        const selectedVariant = event.target as HTMLInputElement;

        const getDimensionValue = (dimension: string): string => (
            selectedVariant.getAttribute(`data-tk-${dimension}-value`) || '0'
        );
        const getDimensionName = (dimension: string): string => (
            selectedVariant.getAttribute(`data-tk-${dimension}-name`) || ''
        );
        const getDimensionUnit = (dimension: string): string => (
            selectedVariant.getAttribute(`data-tk-${dimension}-unit`) || ''
        );
        const getDimensionMin = (): number => (
            Number(selectedVariant.getAttribute('data-tk-min'))
        );
        const getDimensionMax = (): number => (
            Number(selectedVariant.getAttribute('data-tk-max'))
        );
        const getDimensionStep = (): number => (
            Number(selectedVariant.getAttribute('data-tk-step'))
        );
        const getDimensionCheckCd = (): string => (
            selectedVariant.getAttribute('data-tk-art-dim-check-cd') || ''
        );

        const setDimension = (wrapper: HTMLElement, dimension: string): void => {
            const value = getDimensionValue(dimension);
            const fieldElement = wrapper.querySelector<HTMLInputElement>('[data-tk-input-quantity]');
            const nameElement = wrapper.querySelector<HTMLElement>('[data-tk-basket-dim-unit-name]');
            const unitElements = wrapper.querySelectorAll<HTMLElement>('[data-tk-basket-dim-unit]');

            if (!fieldElement) return;
            fieldElement.value = value !== '0' ? value : '';
            fieldElement.setAttribute('data-tk-art-dim-check-cd', getDimensionCheckCd());

            if (getDimensionMin() > 0) {
                fieldElement.setAttribute('min', String(getDimensionMin()));
            } else {
                fieldElement.removeAttribute('min');
            }

            if (getDimensionMax() > 0) {
                fieldElement.setAttribute('max', String(getDimensionMax()));
            } else {
                fieldElement.removeAttribute('max');
            }

            if (getDimensionStep() > 0) {
                fieldElement.setAttribute('step', String(getDimensionStep()));
            } else {
                fieldElement.removeAttribute('step');
            }

            nameElement && (nameElement.textContent = getDimensionName(dimension));

            unitElements.forEach((element) => {
                element.textContent = getDimensionUnit(dimension);
            });
        };

        const dimensionWrappers = this.querySelectorAll<HTMLElement>('[data-tk-dimension]:not([hidden])');
        dimensionWrappers.forEach((wrapper) => {
            const dimension = wrapper.getAttribute('data-tk-dimension');
            dimension && setDimension(wrapper, dimension);
        });

        this.setAttribute('data-tk-variant', selectedVariant.value);
    }
}
