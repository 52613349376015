import TKFormElement from '@tk/controls/tk.form.element';

export default class KWFormElement extends TKFormElement {
    isBoolean: boolean;
    checkboxElement?: HTMLInputElement;
    hiddenElement?: HTMLInputElement;
    defaultValue: string;

    constructor() {
        super();

        this.isBoolean = this.hasAttribute('data-tk-boolean');
        this.checkboxElement = this.querySelector('input[type="checkbox"]') || undefined;
        this.hiddenElement = this.querySelector('input[type="hidden"]') || undefined;
        this.defaultValue = this.hiddenElement?.value || '0';
    }

    connectedCallback(): void {
        super.connectedCallback();

        if (!this.isBoolean) return;
        this.registerBooleanListener();
        this.checkboxElement && this.toggleBoolean(this.checkboxElement);
    }

    registerBooleanListener() {
        if (!this.checkboxElement || !this.hiddenElement) return;
        const onInput = this.handleInput.bind(this);
        this.pushListener({
            event: 'input',
            element: this.checkboxElement,
            action: onInput,
        });
    }

    handleInput(event: InputEvent) {
        const target = event.target as HTMLInputElement;
        this.toggleBoolean(target);
    }

    toggleBoolean(element: HTMLInputElement) {
        if (element.checked) this.hiddenElement!.value = element.value;
        else this.hiddenElement!.value = this.defaultValue;
    }
}
