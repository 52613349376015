import TKCustomElementFactory from '@tk/utilities/tk.custom.element.factory';
import flatpickr from 'flatpickr';
import { Instance } from 'flatpickr/dist/types/instance';
import { Options } from 'flatpickr/dist/types/options';
import { German } from 'flatpickr/dist/l10n/de';
import { French } from 'flatpickr/dist/l10n/fr';
import { Italian } from 'flatpickr/dist/l10n/it';
import { english } from 'flatpickr/dist/l10n/default';

enum DatePickerLocale {
    DE = 'de',
    FR = 'fr',
    IT = 'it',
    EN = 'en',
}

export default class TKFormPicker extends TKCustomElementFactory {
    flatpickr?: Instance;
    fieldElement?: HTMLInputElement;
    localeKey: DatePickerLocale;
    allowMobile: boolean;
    config: Options;

    constructor() {
        super();

        this.fieldElement = this.querySelector('input') || undefined;
        const localeValue = this.getAttribute('data-tk-locale');
        this.localeKey = (
            localeValue && DatePickerLocale[localeValue.toUpperCase() as keyof typeof DatePickerLocale]
        ) || DatePickerLocale.DE;
        this.allowMobile = this.hasAttribute('data-tk-allow-mobile');

        this.config = {
            positionElement: this.fieldElement,
            disableMobile: !this.allowMobile,
            locale: this.localeKey,
            wrap: true,
            allowInput: true,
            onValueUpdate: this.handleValueUpdate.bind(this),
        } as Options;
    }

    connectedCallback() {
        if (!this.fieldElement) return;
        TKFormPicker.initLanguages();
    }

    handleValueUpdate() {
        this.fieldElement?.removeAttribute('data-tk-ignore-field');
        this.fieldElement?.dispatchEvent(new InputEvent('input'));
    }

    static initLanguages() {
        flatpickr.localize(German);
        flatpickr.localize(French);
        flatpickr.localize(Italian);
        flatpickr.localize(english);
    }
}
