import TKCustomElementFactory from '@tk/utilities/tk.custom.element.factory';

export default class TKTabsTab extends TKCustomElementFactory {
    selectorTabsHead: string = '[data-tk-tab-head]';
    selectorTabsBody: string = '[data-tk-tab-body]';
    activeClassName: string;
    initialTabIndex: number;
    tabList?: HTMLCollection;
    tabContentList?: HTMLCollection;
    enableLinks: boolean;

    constructor() {
        super();
        const tabHead = this.querySelector(this.selectorTabsHead);
        const tabBody = this.querySelector(this.selectorTabsBody);
        this.activeClassName = this.getAttribute('data-tk-active-class-name') || 'tk-tabs-tab--active';
        this.initialTabIndex = Number(this.getAttribute('data-tk-initial-tab')) || 1;
        this.enableLinks = this.hasAttribute('data-tk-enable-links');

        if (!tabHead) {
            throw new Error('TK: Missing Tab-Header');
        }

        if (!tabBody) {
            throw new Error('TK: Missing Tab-Body');
        }

        this.tabList = tabHead.childElementCount > 0 ? tabHead.children : undefined;
        this.tabContentList = tabBody.childElementCount > 0 ? tabBody.children : undefined;

        if (!this.tabList) {
            throw new Error('TK: Empty Tab-Header');
        }

        if (!this.tabContentList) {
            throw new Error('TK: Empty Tab-Content');
        }

        if (this.tabList.length !== this.tabContentList.length && !this.enableLinks) {
            throw new Error('TK: Tab-Header Elements and Tab-Content Elements must be equal');
        }
    }

    connectedCallback(): void {
        this.registerListener();
        if (this.tabList && +this.initialTabIndex > 0) {
            const button = this.tabList.item(this.initialTabIndex - 1) as HTMLButtonElement;
            this.setTabActive(button, this.initialTabIndex - 1);
        }
    }

    registerListener(): void {
        if (this.tabList) {
            [...this.tabList].forEach((element, index) => {
                const button = element as HTMLButtonElement;
                const eventHandler = this.setTabActive.bind(this, button, index);
                this.pushListener({ event: 'click', element: button, action: eventHandler });
            });
        }
    }

    setTabActive(button: HTMLButtonElement, index: number): void {
        const input = button.querySelector('input');
        if ((input && input.disabled) || button.disabled) return;
        const item = this.tabContentList?.item(index);
        if (!item) {
            const url = button.getAttribute('data-tk-url');
            const target = button.getAttribute('target') || undefined;
            if (!url) return;
            window.open(url, target);
        } else {
            this.setTabInactive();
            button.classList.add(this.activeClassName);
            item.classList.add(this.activeClassName);
        }
        this.toggleRequiredAttribute(index);
    }

    setTabInactive(): void {
        if (!this.tabContentList) return;
        [...this.tabContentList].forEach((element, index) => {
            const button = element as HTMLButtonElement;
            button.classList.remove(this.activeClassName);
            const item = this.tabList?.item(index);
            if (!item) return;
            item.classList.remove(this.activeClassName);
        });
    }

    toggleRequiredAttribute(index: number) {
        if (!this.tabContentList) return;
        const currentElement = this.tabContentList.item(index);
        const contentList = Array.from(this.tabContentList);
        const filteredList = contentList.filter((element) => element !== currentElement);
        filteredList.forEach((element) => {
            const fieldElements = element.querySelectorAll<HTMLInputElement>('input[required]');
            fieldElements.forEach((fieldElement) => {
                fieldElement.required = false;
                fieldElement.setAttribute('data-tk-is-required', '');
            });
        });
        const fieldElements = currentElement?.querySelectorAll<HTMLInputElement>('input[data-tk-is-required]');
        fieldElements?.forEach((fieldElement) => {
            fieldElement.required = true;
            fieldElement.removeAttribute('data-tk-is-required');
        });
    }
}
